import { makeStyles } from '@mui/styles'
import colors from '../../theme/colors'

const useStyles = makeStyles(() => ({
  body: {
    textAlign: 'center',
  },
  content: {
    textAlign: 'left',
  },
  subTitle: {
    color: colors.theme.green,
    fontWeight: 'bolder',
  },
  facebookIcon: {
    color: colors.facebook,
  },
}))

export default useStyles
