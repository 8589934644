import colors from '../colors'

export default {
  styleOverrides: {
    root: {
      color: colors.black,
      backgroundColor: colors.white,
      borderBottom: `3px ${colors.theme.green} solid`,
    },
  },
}
