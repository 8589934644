import React from 'react'

// mui
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'

interface ImageViewerProps {
  title: string
  imagePath: string
  state: boolean
  setState(value: boolean): void
}

const ImageViewer: React.FC<ImageViewerProps> = (props) => {
  const { title, imagePath, state, setState } = props

  return (
    <Dialog open={state} maxWidth={'lg'}>
      <DialogTitle sx={{ textAlign: 'center' }}>{title}</DialogTitle>
      <DialogContent sx={{ padding: 0 }}>
        <img width="100%" src={imagePath ?? ''} />
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setState(false)}>Cancel</Button>
      </DialogActions>
    </Dialog>
  )
}

export default ImageViewer
