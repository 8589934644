import React from 'react'
import ProjectPanel from '../../../components/ProjectPanel'
import { withTranslation } from 'react-i18next'
import completedProjectList from './data'

const CompletedProject:React.FC = (props:any) => {
  const { t, contentHeight } = props

  return (
    <ProjectPanel
      title={t('completedProjects')}
      projectData={completedProjectList}
      contentHeight={contentHeight} />
  )
}

export default React.memo(withTranslation()(CompletedProject))