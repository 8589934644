import { makeStyles } from '@mui/styles'
import colors from '../../../../../theme/colors'

const useStyles = makeStyles(() => ({
  menu: {
    float: 'right',
    display: 'inline-flex',
  },
  menuNavItem: {
    borderRadius: '15px 0px 15px 0px !important',
    margin: '10px !important',
    padding: '8px 15px !important',

    '&:hover': {
      borderRadius: '15px 0px 15px 0px !important',
      padding: '8px 15px !important',
      color: `${colors.theme.green} !important`,
      fontWeight: 600,
    },
  },
  menuNavItemLink: {
    textDecoration: 'none !important',
    color: colors.black,
    fontSize: '10pt'
  },
  menuDropDown: {
    paddingLeft: 8,
  },
  getInTouch: {
    color: `${colors.white} !important`,
    backgroundColor: `${colors.theme.green} !important`,
    borderRadius: '15px 0px 15px 0px !important',

    '& a': {
      color: `${colors.white} !important`,
    },
  },
}))

export default useStyles
