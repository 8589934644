import React from 'react'
import { Container, Box, Grid, Typography } from '@mui/material/'
import { withTranslation } from 'react-i18next'
import useStyles from './styles'
import FacebookIcon from '@mui/icons-material/Facebook'

const ContactUs:React.FC = (props:any) => {
  const { t, contentHeight } = props
  const styleClasses = useStyles()

  return (
    <Container>
      <Box marginTop={8} marginBottom={5}>
        <Grid
          container
          spacing={2}
          justifyContent="center"
          sx={{ minHeight: contentHeight - 10 }}>
          <Grid item xs={12} sm={12} md={7} lg={7}>
            <div className="mapouter">
              <div className="gmap_canvas">
                <iframe
                  width="100%"
                  height="450"
                  id="gmap_canvas"
                  src="https://maps.google.com/maps?q=Taman%20Saujana%20Idaman%20Sales%20Gallery&t=k&z=15&ie=UTF8&iwloc=&output=embed"
                  frameBorder="0"
                  scrolling="no"
                  marginHeight={0}
                  marginWidth={0}
                ></iframe>
              </div>
            </div>
          </Grid>
          <Grid item xs={12} sm={12} md={5} lg={5}>
            <div className={styleClasses.body}>
              <Typography variant="h4" gutterBottom component="div">
                {t('getInTouch').toUpperCase()}
              </Typography>
              <br />
              {/* ADDRESS */}
              <div>
                <Typography
                  className={styleClasses.subTitle}
                  variant="h5"
                  gutterBottom
                  component="div"
                >
                  {t('address')}
                </Typography>
                <Typography variant="body1" gutterBottom>
                  <strong>Taman Saujana Idaman Sales Gallery</strong>
                  <br />
                  KM6, Jalan Kangar - Arau, <br />
                  Kubang Gajah, 02600 Arau, <br />
                  Perlis.
                </Typography>
              </div>
              <br />
              <div>
                <Typography
                  className={styleClasses.subTitle}
                  variant="h5"
                  gutterBottom
                  component="div"
                >
                  {t('contactNumber')}
                </Typography>
                <Typography variant="body1" gutterBottom>
                  +604-976 2554
                </Typography>
              </div>
              <br />
              <div>
                <Typography
                  className={styleClasses.subTitle}
                  variant="h5"
                  gutterBottom
                  component="div"
                >
                  {t('emailAddress')}
                </Typography>
                <Typography variant="body1" gutterBottom>
                  <a
                    href="mailto:tamansaujanaidaman@gmail.com"
                    target="_blank"
                    rel="noreferrer"
                  >
                    tamansaujanaidaman@gmail.com
                  </a>
                </Typography>
              </div>
              <br />

              {/* SOCIAL MEDIA */}
              <div>
                <Typography
                  className={styleClasses.subTitle}
                  variant="h5"
                  gutterBottom
                  component="div"
                >
                  {t('socialMedia')}
                </Typography>
                <a
                  target="_blank"
                  href="https://www.facebook.com/TamanSaujanaIdaman/"
                  rel="noreferrer"
                >
                  <FacebookIcon
                    className={styleClasses.facebookIcon}
                    fontSize={'large'}
                  />
                </a>
              </div>
            </div>
          </Grid>
        </Grid>
      </Box>
    </Container>
  )
}

export default React.memo(withTranslation()(ContactUs))