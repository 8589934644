import React from 'react'
import { Link, useLocation } from 'react-router-dom'
import { Card, CardHeader, CardActions, Button,  CardMedia } from '@mui/material'
import { useTranslation } from 'react-i18next'
import useStyles from './styles'

interface IProjectCard {
  projectKey: string
  title: string
  coverPath: string
  subTitle?: string
}

const ProjectCard:React.FC<IProjectCard> = ({ projectKey, title, coverPath, subTitle } : IProjectCard) => {
  const { t  } = useTranslation()
  const location = useLocation()
  const styleClasses = useStyles()

  return (
    <Card
      variant={'elevation'}
      elevation={6}>
      <CardHeader
        className={styleClasses.cardTitle}
        title={title}
        titleTypographyProps={{
          variant:'h6'
        }}
        subheader={subTitle}
        subheaderTypographyProps={{
          variant: 'h6',
          className: styleClasses.cardSubTitle

        }}/>
      <CardMedia
        className={styleClasses.cardMedia}
        image={coverPath}
        component='img'
        title={title} />
      <CardActions className={styleClasses.cardActions}>
        <Link
          to={`${location.pathname}/${projectKey}`}
          className={styleClasses.link}>
          <Button size='medium' variant='outlined'>
            { t('view') }
          </Button>
        </Link>
      </CardActions>
    </Card>
  )
}

export default React.memo(ProjectCard)
