import React from 'react'
import { withTranslation } from 'react-i18next'
import { Swiper, SwiperSlide } from 'swiper/react'
import useStyles from './styles'
import { SwiperSlide as vSwiperSlide } from '../../types'
import slideList from './swiperData'

import 'swiper/css';
import 'swiper/css/effect-fade';

import { Autoplay, EffectFade } from 'swiper'

const Home:React.FC = (props:any) => {
  const { contentHeight } = props
  const styleClasses = useStyles()


  return (
    <Swiper
      modules={[ Autoplay, EffectFade ]}
      effect={'fade'}
      autoplay={{ delay: 5000, waitForTransition: true }}
      autoHeight={true}
      navigation={false}
      loop={true}
      style={{height: contentHeight}}>
      {
        slideList.map((ss: vSwiperSlide, index: number) => {
          return (
            <SwiperSlide key={`swiper-${index}`}>
              <div className={styleClasses.caption}>
                <div className={styleClasses.captionText}>{ss.caption}</div>
              </div>
              <img
                height={contentHeight}
                width='100%'
                src={ss.imagePath} />
            </SwiperSlide>
          )
        })
      }
    </Swiper>
  )
}
export default React.memo(withTranslation()(Home))