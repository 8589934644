import React from 'react'
import { Grid, Container, Typography, Divider } from '@mui/material'
import { withTranslation } from 'react-i18next'
import useStyles from './styles'

const Overview:React.FC = (props:any) => {
  const { t, contentHeight } = props
  const styleClasses = useStyles()

  return (
    <Container className={styleClasses.root}>
      <Grid
        container
        sx={{ minHeight: contentHeight }}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Typography
              variant='h4'
              gutterBottom
              className={styleClasses.title}
              component='div'>
              { t('ourStory') }
            </Typography>
            <Divider />
            <Grid className={styleClasses.contentSection} container spacing={3}>
              <Grid item xs={12} sm={12} md={4} lg={4}>
                <img className={styleClasses.image} src="/images/overview_1.jpg" />
              </Grid>
              <Grid item xs={12} sm={12} md={8} lg={8}>
                <Typography variant='h5' gutterBottom className={styleClasses.textContent}>
                  { t('ourStoryContent') }
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Typography
              variant='h4'
              gutterBottom
              className={styleClasses.title}
              component='div'>
              { t('ourCompany') }
            </Typography>
            <Divider />
            <Grid className={styleClasses.contentSection} container spacing={3}>
              <Grid item xs={12} sm={12} md={4} lg={4}>
                <img className={styleClasses.image} src="/images/overview_2.jpg" />
              </Grid>
              <Grid item xs={12} sm={12} md={8} lg={8}>
                <Typography variant='h5' gutterBottom className={styleClasses.textContent}>
                  { t('ourCompanyContent') }
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  )
}

export default React.memo(withTranslation()(Overview))
