import { makeStyles } from '@mui/styles'
import colors from '../../../theme/colors'

const useStyles = makeStyles(() => ({
  root: {
    paddingTop: 30,
    textAlign: 'justify'
  },
  image: {
    height: 280,
    width: '100%',
    boxShadow:
      '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
  },
  title: {
    color: colors.theme.green,
    fontWeight: 'bolder',
    textTransform: 'uppercase'

  },
  contentSection: {
    paddingTop: '25px',
    paddingBottom: '15px',
  },
  textContent: {
    textAlign: 'justify',
    wordSpacing: '2',
    lineHeight: '30px !important',
    fontSize: '14pt !important',
  },
}))

export default useStyles
