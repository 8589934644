import { Project } from '../../../types'

const constructionProjectList: Project[] = [
  {
    key: 'jalanSukaManantiBridge',
    title: 'Bridge Jalan Suka Menanti',
    description: 'Bridge Jalan Suka Menanti',
    location: 'Alor Setar, Kedah',
    gdv: 'MYR 25 mil',
    contract: '2015-2018',
    cover: '/images/projects/constructions/jalan_suka_mananti_bridge/jalan_suka_mananti_bridge_1.jpg',
    flyers: [],
    images: [
      {
        title: 'Jalan Suka Mananti Bridge',
        description: '',
        tags: [],
        imagePath: '/images/projects/constructions/jalan_suka_mananti_bridge/jalan_suka_mananti_bridge_1.jpg',
      },
      {
        title: 'Jalan Suka Mananti Bridge',
        description: '',
        tags: [],
        imagePath: '/images/projects/constructions/jalan_suka_mananti_bridge/jalan_suka_mananti_bridge_2.jpg',
      },
      {
        title: 'Jalan Suka Mananti Bridge',
        description: '',
        tags: [],
        imagePath: '/images/projects/constructions/jalan_suka_mananti_bridge/jalan_suka_mananti_bridge_3.jpg',
      },
      {
        title: 'Jalan Suka Mananti Bridge',
        description: '',
        tags: [],
        imagePath: '/images/projects/constructions/jalan_suka_mananti_bridge/jalan_suka_mananti_bridge_4.jpg',
      },
    ]
  },
  {
    key: 'tamanUniversitiJaya',
    title: 'Taman Universiti Jaya',
    description: 'Taman Universiti Jaya 2A & 2B',
    location: 'Semeling, Kedah',
    gdv: 'MYR 36 mil',
    contract: '2018-2021',
    cover: '/images/projects/constructions/taman_universiti_jaya/taman_universiti_jaya_1.jpg',
    flyers: [],
    images: [
      {
        title: 'Taman Universiti Jaya',
        description: '',
        tags: [],
        imagePath: '/images/projects/constructions/taman_universiti_jaya/taman_universiti_jaya_1.jpg',
      },
      {
        title: 'Taman Universiti Jaya',
        description: '',
        tags: [],
        imagePath: '/images/projects/constructions/taman_universiti_jaya/taman_universiti_jaya_2.jpg',
      },
      {
        title: 'Taman Universiti Jaya',
        description: '',
        tags: [],
        imagePath: '/images/projects/constructions/taman_universiti_jaya/taman_universiti_jaya_3.jpg',
      },
      {
        title: 'Taman Universiti Jaya',
        description: '',
        tags: [],
        imagePath: '/images/projects/constructions/taman_universiti_jaya/taman_universiti_jaya_4.jpg',
      },
    ]
  },
  {
    key: 'tertiaryIrrigationSystem',
    title: 'Tertiary Irrigation System',
    description: 'Tertiary Irrigation System, Mada Block 4, 4A & 5',
    location: 'Alor Setar, Kedah',
    gdv: 'MYR 21.5 mil',
    contract: '2015-2017',
    cover: '/images/projects/constructions/tertiary_irrigation_system/tertiary_irrigation_system_1.jpg',
    flyers: [],
    images: [
      {
        title: 'Tertiary Irrigation System',
        description: '',
        tags: [],
        imagePath: '/images/projects/constructions/tertiary_irrigation_system/tertiary_irrigation_system_1.jpg',
      },
      {
        title: 'Tertiary Irrigation System',
        description: '',
        tags: [],
        imagePath: '/images/projects/constructions/tertiary_irrigation_system/tertiary_irrigation_system_2.jpg',
      },
      {
        title: 'Tertiary Irrigation System',
        description: '',
        tags: [],
        imagePath: '/images/projects/constructions/tertiary_irrigation_system/tertiary_irrigation_system_3.jpg',
      },
      {
        title: 'Tertiary Irrigation System',
        description: '',
        tags: [],
        imagePath: '/images/projects/constructions/tertiary_irrigation_system/tertiary_irrigation_system_4.jpg',
      },
      {
        title: 'Tertiary Irrigation System',
        description: '',
        tags: [],
        imagePath: '/images/projects/constructions/tertiary_irrigation_system/tertiary_irrigation_system_5.jpg',
      },
    ]
  },
  {
    key: 'jalanSukaMenantiK137',
    title: 'Upgrading Jalan Suka Menanti-K137',
    description: 'Upgrading Jalan Suka Menanti-K137',
    location: 'Alor Setar, Kedah',
    gdv: 'MYR 65.3 mil',
    contract: '2018-2021',
    cover:  '/images/projects/constructions/jalan_suka_menanti_k_137/jalan_suka_menanti_k_137_1.jpg',
    flyers: [],
    images: [
      {
        title: 'Upgrading Jalan Suka Menanti-K137',
        description: '',
        tags: [],
        imagePath: '/images/projects/constructions/jalan_suka_menanti_k_137/jalan_suka_menanti_k_137_1.jpg',
      },
      {
        title: 'Upgrading Jalan Suka Menanti-K137',
        description: '',
        tags: [],
        imagePath: '/images/projects/constructions/jalan_suka_menanti_k_137/jalan_suka_menanti_k_137_2.jpg',
      },
      {
        title: 'Upgrading Jalan Suka Menanti-K137',
        description: '',
        tags: [],
        imagePath: '/images/projects/constructions/jalan_suka_menanti_k_137/jalan_suka_menanti_k_137_3.jpg',
      },
      {
        title: 'Upgrading Jalan Suka Menanti-K137',
        description: '',
        tags: [],
        imagePath: '/images/projects/constructions/jalan_suka_menanti_k_137/jalan_suka_menanti_k_137_4.jpg',
      },
      {
        title: 'Upgrading Jalan Suka Menanti-K137',
        description: '',
        tags: [],
        imagePath: '/images/projects/constructions/jalan_suka_menanti_k_137/jalan_suka_menanti_k_137_5.jpg',
      },
      {
        title: 'Upgrading Jalan Suka Menanti-K137',
        description: '',
        tags: [],
        imagePath: '/images/projects/constructions/jalan_suka_menanti_k_137/jalan_suka_menanti_k_137_6.jpg',
      },
      {
        title: 'Upgrading Jalan Suka Menanti-K137',
        description: '',
        tags: [],
        imagePath: '/images/projects/constructions/jalan_suka_menanti_k_137/jalan_suka_menanti_k_137_7.jpg',
      },
      {
        title: 'Upgrading Jalan Suka Menanti-K137',
        description: '',
        tags: [],
        imagePath: '/images/projects/constructions/jalan_suka_menanti_k_137/jalan_suka_menanti_k_137_8.jpg',
      },
      {
        title: 'Upgrading Jalan Suka Menanti-K137',
        description: '',
        tags: [],
        imagePath: '/images/projects/constructions/jalan_suka_menanti_k_137/jalan_suka_menanti_k_137_9.jpg',
      },
      {
        title: 'Upgrading Jalan Suka Menanti-K137',
        description: '',
        tags: [],
        imagePath: '/images/projects/constructions/jalan_suka_menanti_k_137/jalan_suka_menanti_k_137_10.jpg',
      },
      {
        title: 'Upgrading Jalan Suka Menanti-K137',
        description: '',
        tags: [],
        imagePath: '/images/projects/constructions/jalan_suka_menanti_k_137/jalan_suka_menanti_k_137_11.jpg',
      },
      {
        title: 'Upgrading Jalan Suka Menanti-K137',
        description: '',
        tags: [],
        imagePath: '/images/projects/constructions/jalan_suka_menanti_k_137/jalan_suka_menanti_k_137_12.jpg',
      },

      {
        title: 'Upgrading Jalan Suka Menanti-K137',
        description: '',
        tags: [],
        imagePath: '/images/projects/constructions/jalan_suka_menanti_k_137/jalan_suka_menanti_k_137_13.jpg',
      },
      {
        title: 'Upgrading Jalan Suka Menanti-K137',
        description: '',
        tags: [],
        imagePath: '/images/projects/constructions/jalan_suka_menanti_k_137/jalan_suka_menanti_k_137_14.jpg',
      },
      {
        title: 'Upgrading Jalan Suka Menanti-K137',
        description: '',
        tags: [],
        imagePath: '/images/projects/constructions/jalan_suka_menanti_k_137/jalan_suka_menanti_k_137_15.jpg',
      },
      {
        title: 'Upgrading Jalan Suka Menanti-K137',
        description: '',
        tags: [],
        imagePath: '/images/projects/constructions/jalan_suka_menanti_k_137/jalan_suka_menanti_k_137_16.jpg',
      },
      {
        title: 'Upgrading Jalan Suka Menanti-K137',
        description: '',
        tags: [],
        imagePath: '/images/projects/constructions/jalan_suka_menanti_k_137/jalan_suka_menanti_k_137_17.jpg',
      },
      {
        title: 'Upgrading Jalan Suka Menanti-K137',
        description: '',
        tags: [],
        imagePath: '/images/projects/constructions/jalan_suka_menanti_k_137/jalan_suka_menanti_k_137_18.jpg',
      },
      {
        title: 'Upgrading Jalan Suka Menanti-K137',
        description: '',
        tags: [],
        imagePath: '/images/projects/constructions/jalan_suka_menanti_k_137/jalan_suka_menanti_k_137_19.jpg',
      },
      {
        title: 'Upgrading Jalan Suka Menanti-K137',
        description: '',
        tags: [],
        imagePath: '/images/projects/constructions/jalan_suka_menanti_k_137/jalan_suka_menanti_k_137_20.jpg',
      },
      {
        title: 'Upgrading Jalan Suka Menanti-K137',
        description: '',
        tags: [],
        imagePath: '/images/projects/constructions/jalan_suka_menanti_k_137/jalan_suka_menanti_k_137_21.jpg',
      },
      {
        title: 'Upgrading Jalan Suka Menanti-K137',
        description: '',
        tags: [],
        imagePath: '/images/projects/constructions/jalan_suka_menanti_k_137/jalan_suka_menanti_k_137_22.jpg',
      },
      {
        title: 'Upgrading Jalan Suka Menanti-K137',
        description: '',
        tags: [],
        imagePath: '/images/projects/constructions/jalan_suka_menanti_k_137/jalan_suka_menanti_k_137_23.jpg',
      },
      {
        title: 'Upgrading Jalan Suka Menanti-K137',
        description: '',
        tags: [],
        imagePath: '/images/projects/constructions/jalan_suka_menanti_k_137/jalan_suka_menanti_k_137_24.jpg',
      },
    ]
  },
  {
    key: 'hospitalPendang',
    title: 'Hospital Pendang',
    description: 'Building Khas & Infrastructure, Road and Drainage of Hospital Pendang',
    location: 'Pendang, Kedah',
    gdv: 'MYR 8.75 mil',
    contract: '2018-2020',
    cover: '/images/projects/constructions/hospital_pendang/hospital_pendang_1.jpg',
    flyers: [],
    images: [
      {
        title: 'Hospital Pendang',
        description: '',
        tags: [],
        imagePath: '/images/projects/constructions/hospital_pendang/hospital_pendang_1.jpg',
      },
      {
        title: 'Hospital Pendang',
        description: '',
        tags: [],
        imagePath: '/images/projects/constructions/hospital_pendang/hospital_pendang_2.jpg',
      },
      {
        title: 'Hospital Pendang',
        description: '',
        tags: [],
        imagePath: '/images/projects/constructions/hospital_pendang/hospital_pendang_3.jpg',
      },
      {
        title: 'Hospital Pendang',
        description: '',
        tags: [],
        imagePath: '/images/projects/constructions/hospital_pendang/hospital_pendang_4.jpg',
      },
      {
        title: 'Hospital Pendang',
        description: '',
        tags: [],
        imagePath: '/images/projects/constructions/hospital_pendang/hospital_pendang_5.jpg',
      },
      {
        title: 'Hospital Pendang',
        description: '',
        tags: [],
        imagePath: '/images/projects/constructions/hospital_pendang/hospital_pendang_6.jpg',
      },
      {
        title: 'Hospital Pendang',
        description: '',
        tags: [],
        imagePath: '/images/projects/constructions/hospital_pendang/hospital_pendang_7.jpg',
      },
    ]
  },
  {
    key: 'masjidPutra',
    title: 'Masjid Putra',
    description: 'Upgrade Masjid Putra',
    location: 'Wilayah Persekutuan Putrajaya',
    gdv: 'MYR 12.6 mil',
    contract: '2020-2022',
    cover: '/images/projects/constructions/masjid_putra/masjid_putra_4.jpg',
    flyers: [],
    images: [
      {
        title: 'Upgrading Jalan Suka Menanti-K137',
        description: '',
        tags: [],
        imagePath: '/images/projects/constructions/masjid_putra/masjid_putra_1.jpg',
      },
      {
        title: 'Upgrading Jalan Suka Menanti-K137',
        description: '',
        tags: [],
        imagePath: '/images/projects/constructions/masjid_putra/masjid_putra_2.jpg',
      },
      {
        title: 'Upgrading Jalan Suka Menanti-K137',
        description: '',
        tags: [],
        imagePath: '/images/projects/constructions/masjid_putra/masjid_putra_3.jpg',
      },
      {
        title: 'Upgrading Jalan Suka Menanti-K137',
        description: '',
        tags: [],
        imagePath: '/images/projects/constructions/masjid_putra/masjid_putra_4.jpg',
      },
      {
        title: 'Upgrading Jalan Suka Menanti-K137',
        description: '',
        tags: [],
        imagePath: '/images/projects/constructions/masjid_putra/masjid_putra_5.jpg',
      },
      {
        title: 'Upgrading Jalan Suka Menanti-K137',
        description: '',
        tags: [],
        imagePath: '/images/projects/constructions/masjid_putra/masjid_putra_6.jpg',
      },
      {
        title: 'Upgrading Jalan Suka Menanti-K137',
        description: '',
        tags: [],
        imagePath: '/images/projects/constructions/masjid_putra/masjid_putra_7.jpg',
      },
      {
        title: 'Upgrading Jalan Suka Menanti-K137',
        description: '',
        tags: [],
        imagePath: '/images/projects/constructions/masjid_putra/masjid_putra_8.jpg',
      },
      {
        title: 'Upgrading Jalan Suka Menanti-K137',
        description: '',
        tags: [],
        imagePath: '/images/projects/constructions/masjid_putra/masjid_putra_9.jpg',
      },
      {
        title: 'Upgrading Jalan Suka Menanti-K137',
        description: '',
        tags: [],
        imagePath: '/images/projects/constructions/masjid_putra/masjid_putra_10.jpg',
      },
      {
        title: 'Upgrading Jalan Suka Menanti-K137',
        description: '',
        tags: [],
        imagePath: '/images/projects/constructions/masjid_putra/masjid_putra_11.jpg',
      },
      {
        title: 'Upgrading Jalan Suka Menanti-K137',
        description: '',
        tags: [],
        imagePath: '/images/projects/constructions/masjid_putra/masjid_putra_12.jpg',
      },
      {
        title: 'Upgrading Jalan Suka Menanti-K137',
        description: '',
        tags: [],
        imagePath: '/images/projects/constructions/masjid_putra/masjid_putra_13.jpg',
      },
      {
        title: 'Upgrading Jalan Suka Menanti-K137',
        description: '',
        tags: [],
        imagePath: '/images/projects/constructions/masjid_putra/masjid_putra_14.jpg',
      },
      {
        title: 'Upgrading Jalan Suka Menanti-K137',
        description: '',
        tags: [],
        imagePath: '/images/projects/constructions/masjid_putra/masjid_putra_15.jpg',
      },
      {
        title: 'Upgrading Jalan Suka Menanti-K137',
        description: '',
        tags: [],
        imagePath: '/images/projects/constructions/masjid_putra/masjid_putra_16.jpg',
      },
    ]
  },
  {
    key: 'residensiAkasiaJubilee',
    title: 'Residensi Akasia Jubilee',
    description: 'Residensi Akasia Jubilee',
    location: 'Kuala Lumpur',
    gdv: 'MYR 96 mil',
    contract: '2020-2023',
    cover: '/images/projects/constructions/residensi_akasia_jubilee/residensi_akasia_jubilee_3.jpg',
    flyers: [],
    images: [
      {
        title: 'Residensi Akasia Jubilee',
        description: '',
        tags:[],
        imagePath: '/images/projects/constructions/residensi_akasia_jubilee/residensi_akasia_jubilee_1.jpg',
      },
      {
        title: 'Residensi Akasia Jubilee',
        description: '',
        tags:[],
        imagePath: '/images/projects/constructions/residensi_akasia_jubilee/residensi_akasia_jubilee_2.jpg',
      },
      {
        title: 'Residensi Akasia Jubilee',
        description: '',
        tags:[],
        imagePath: '/images/projects/constructions/residensi_akasia_jubilee/residensi_akasia_jubilee_3.jpg',
      },
      {
        title: 'Residensi Akasia Jubilee',
        description: '',
        tags:[],
        imagePath: '/images/projects/constructions/residensi_akasia_jubilee/residensi_akasia_jubilee_4.jpg',
      },
      {
        title: 'Residensi Akasia Jubilee',
        description: '',
        tags:[],
        imagePath: '/images/projects/constructions/residensi_akasia_jubilee/residensi_akasia_jubilee_5.jpg',
      },
      {
        title: 'Residensi Akasia Jubilee',
        description: '',
        tags:[],
        imagePath: '/images/projects/constructions/residensi_akasia_jubilee/residensi_akasia_jubilee_6.jpg',
      },
      {
        title: 'Residensi Akasia Jubilee',
        description: '',
        tags:[],
        imagePath: '/images/projects/constructions/residensi_akasia_jubilee/residensi_akasia_jubilee_7.jpg',
      },
      {
        title: 'Residensi Akasia Jubilee',
        description: '',
        tags:[],
        imagePath: '/images/projects/constructions/residensi_akasia_jubilee/residensi_akasia_jubilee_8.jpg',
      },
      {
        title: 'Residensi Akasia Jubilee',
        description: '',
        tags:[],
        imagePath: '/images/projects/constructions/residensi_akasia_jubilee/residensi_akasia_jubilee_9.jpg',
      },
      {
        title: 'Residensi Akasia Jubilee',
        description: '',
        tags:[],
        imagePath: '/images/projects/constructions/residensi_akasia_jubilee/residensi_akasia_jubilee_10.jpg',
      },
      {
        title: 'Residensi Akasia Jubilee',
        description: '',
        tags:[],
        imagePath: '/images/projects/constructions/residensi_akasia_jubilee/residensi_akasia_jubilee_11.jpg',
      },
      {
        title: 'Residensi Akasia Jubilee',
        description: '',
        tags:[],
        imagePath: '/images/projects/constructions/residensi_akasia_jubilee/residensi_akasia_jubilee_12.jpg',
      },
      {
        title: 'Residensi Akasia Jubilee',
        description: '',
        tags:[],
        imagePath: '/images/projects/constructions/residensi_akasia_jubilee/residensi_akasia_jubilee_13.jpg',
      },
    ]
  },
  {
    key: 'taipingWakafHotel',
    title: 'Taiping Wakaf Hotel',
    description: 'Taiping Wakaf Hotel',
    location: '',
    gdv: '',
    contract: '',
    cover: '/images/projects/constructions/taipeng_wakaf_hotel/taipeng_wakaf_hotel_1.jpg',
    flyers: [],
    images: [
      {
        title: 'Taipeng Wakaf Hotel',
        description: '',
        tags: [],
        imagePath: '/images/projects/constructions/taipeng_wakaf_hotel/taipeng_wakaf_hotel_1.jpg',
      },
      {
        title: 'Taipeng Wakaf Hotel',
        description: '',
        tags: [],
        imagePath: '/images/projects/constructions/taipeng_wakaf_hotel/taipeng_wakaf_hotel_2.jpg',
      },
      {
        title: 'Taipeng Wakaf Hotel',
        description: '',
        tags: [],
        imagePath: '/images/projects/constructions/taipeng_wakaf_hotel/taipeng_wakaf_hotel_3.jpg',
      },
      {
        title: 'Taipeng Wakaf Hotel',
        description: '',
        tags: [],
        imagePath: '/images/projects/constructions/taipeng_wakaf_hotel/taipeng_wakaf_hotel_4.jpg',
      },
      {
        title: 'Taipeng Wakaf Hotel',
        description: '',
        tags: [],
        imagePath: '/images/projects/constructions/taipeng_wakaf_hotel/taipeng_wakaf_hotel_5.jpg',
      },
      {
        title: 'Taipeng Wakaf Hotel',
        description: '',
        tags: [],
        imagePath: '/images/projects/constructions/taipeng_wakaf_hotel/taipeng_wakaf_hotel_6.jpg',
      },
    ]
  },
]

export default constructionProjectList
