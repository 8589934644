import React from 'react'
import clsx from 'clsx'
import { Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import useStyles from './styles'


const EmptyData:React.FC = () => {
  const { t  } = useTranslation();
  const styleClasses = useStyles()

  return (
    <>
      <div>
        <span className={clsx('mdi mdi-emoticon-dead', styleClasses.noDataIcon) } />
      </div>
      <Typography variant='h2' component='div' className={styleClasses.noDataWord}>
        { t('noData') }
      </Typography>
    </>

  )
}

export default React.memo(EmptyData)
