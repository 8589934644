import React from 'react'
import { Grid, Container, Typography, Card, CardHeader, CardContent, CardMedia } from '@mui/material'
import { withTranslation } from 'react-i18next'
import { OurService } from '../../../types'
import useStyles from './styles'
import serviceList from './serviceData'

const OurServices:React.FC = (props:any) => {
  const { t, contentHeight } = props
  const styleClasses = useStyles()

  return (
    <Container className={styleClasses.root}>
      <div className={styleClasses.pageTitle}>
        <Typography
          variant='h4'
          gutterBottom
          component='div'>
          { t('ourServices') }
        </Typography>
      </div>
      <Grid
        container
        spacing={4}
        sx={{ minHeight: contentHeight }}>
        {
          serviceList.map((sl: OurService, index: number) => {
            return (
              <Grid key={`grid-${sl.title}-${index}`} item xs={12} sm={12} md={4} lg={4}>
                <Card
                  sx={{ maxWidth: 345 }}
                  variant={'elevation'}
                  elevation={12}>
                  <CardHeader
                    className={styleClasses.cardTitle}
                    title={sl.title}/>
                  <CardMedia
                    className={styleClasses.cardMedia}
                    image={sl.imagePath}
                    component='img'
                    title={sl.title} />
                  <CardContent>
                    <Typography variant='h6' color='div' className={styleClasses.cardContent}>
                      {sl.content}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            )
          })
        }
      </Grid>
    </Container>
  )
}

export default React.memo(withTranslation()(OurServices))
