import React from 'react'
import ProjectPanel from '../../../components/ProjectPanel'
import { withTranslation } from 'react-i18next'
import futureProjectList from './data'

const FutureProject:React.FC = (props:any) => {
  const { t, contentHeight } = props

  return (
    <ProjectPanel
      title={t('futureProjects')}
      projectData={futureProjectList}
      contentHeight={contentHeight} />
  )
}

export default React.memo(withTranslation()(FutureProject))