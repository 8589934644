import { makeStyles } from '@mui/styles'
import colors from '../../../theme/colors'

const useStyles = makeStyles(() => ({
  footer: {
    padding: '20px',
    width: '100%',
    height: '100px',
    background: colors.grey,
    color: colors.white,
    textAlign: 'center',
  },
  footerContent: {
    textAlign: 'center',
  },
  copyrightContainer: {
    padding: 10,
  },
}))

export default useStyles
