export default {
  grey: '#1c1a1a',
  grey_light: '#ededed',
  facebook: '#4267B2',
  black: '#000000',
  black_100: '#242222',
  white: '#ffffff',
  white_100: '#f0ebeb',
  theme: {
    green: '#276955',
    green_100: '#2c9978',
    red: '#e2332e',
    purple: '#341c50',
  },
}
