import { OurService } from '../../../types'
import i18n from '../../../i18n'

const servicesList: OurService[] = [
  {
    imagePath: '/images/services/house_loan.jpg',
    alt: i18n.t('houseLoan'),
    title: i18n.t('houseLoanApply'),
    content: i18n.t('houseLoanApplyContent')
  },
  {
    imagePath: '/images/services/legal_consultant.jpg',
    alt: i18n.t('legalConsultant'),
    title: i18n.t('legalFeeConsultant'),
    content: i18n.t('legalFeeConsultantContent')
  },
  {
    imagePath: '/images/services/project_briefying.jpg',
    alt: i18n.t('projectBriefying'),
    title: i18n.t('projectBriefying'),
    content: i18n.t('projectBriefyingContent')
  },
  {
    imagePath: '/images/services/epf_withdrawal.jpg',
    alt: i18n.t('epfWithdrawal'),
    title: i18n.t('epfWithdrawal'),
    content: i18n.t('epfWithdrawalContent')
  },
  {
    imagePath: '/images/services/my_home.png',
    alt: i18n.t('myHome'),
    title: i18n.t('myHomeScheme'),
    content: i18n.t('myHomeSchemeContent')
  },
  {
    imagePath: '/images/services/ppam.png',
    alt: i18n.t('ppam'),
    title: i18n.t('ppamScheme'),
    content: i18n.t('ppamSchemeContent')
  },
]

export default servicesList
