import { makeStyles } from '@mui/styles'
import colors from '../../../../../theme/colors'

const useStyles = makeStyles(() => ({
  root: {
    padding: 10,
    display: 'inline-flex',
    textAlign: 'center'
  },
  divider: {
    backgroundColor: colors.white
  },
  link: {
    textDecoration: 'none',
    color: colors.white,
    paddingLeft: 8,
    paddingRight: 8
  }
}))

export default useStyles
