import React from 'react'
import clsx from 'clsx'
import { Link } from 'react-router-dom'
import { Drawer, List, ListItemButton, ListItemText, Collapse } from '@mui/material'
import useStyles from './styles'

import { Route } from '../../../../../types'
import routesConfig from '../../../../../routes/config'


interface IAppDrawer {
  drawerState: boolean,
  handleDraweState: () => void
}

const AppDrawer: React.FC<IAppDrawer> = ({ drawerState, handleDraweState }: IAppDrawer) => {
  const [subMenuState, setSubMenuState] = React.useState('')
  const styleClasses = useStyles()

  const onHandleSubMenuStateChange = (key) => {
    setSubMenuState(key)
  }

  return (
    <Drawer anchor='bottom' open={drawerState} onClose={()=> { handleDraweState() }}>
      <List component={'nav'}>
        {
          routesConfig.map((route:Route, index:number) => {

            if (route.isEnable && !route.isHide) {
              if (route.expand) {
                const uniqueMenuKey = `menu-${route.title}`
                return (
                  <>
                    <ListItemButton
                      key={`list-button-${index}`}
                      onClick={()=> { onHandleSubMenuStateChange(uniqueMenuKey)}}>
                      <ListItemText
                        primary={route.title}
                        className={styleClasses.title} />
                      <span className={clsx('mdi', 'mdi-chevron-down', styleClasses.title)} />
                    </ListItemButton>
                    <Collapse in={subMenuState===uniqueMenuKey} timeout="auto" unmountOnExit>
                      {
                        route.subRoutes && route.subRoutes.length > 0
                          ? route.subRoutes.map((sRoute: Route, sIndex: number) => {
                            if (sRoute.isEnable && !sRoute.isHide) {
                              return (
                                <Link key={`link-sub-nav-${index}-${sIndex}`}
                                  to={sRoute.path ?? ''}
                                  className={styleClasses.link}>
                                  <ListItemButton key={`list-button-${index}-${sIndex}`}
                                    onClick={()=> {
                                      handleDraweState()
                                      onHandleSubMenuStateChange('')
                                    }}>
                                    <ListItemText
                                      primary={sRoute.title}
                                      className={styleClasses.title} />
                                  </ListItemButton>
                                </Link>
                              )
                            }
                            return null
                          })
                          : null
                      }
                    </Collapse>
                  </>
                )
              } else {
                return (
                  <Link key={`link-${index}`}
                    to={route.path ?? ''}
                    className={styleClasses.link}
                    onClick={()=> { handleDraweState() }}>
                    <ListItemButton key={`list-button-${index}`}>
                      <ListItemText
                        primary={route.title}
                        className={styleClasses.title} />
                    </ListItemButton>
                  </Link>
                )
              }
            }
            return null;
          })
        }
      </List>

    </Drawer>
  )
}

export default React.memo(AppDrawer)
