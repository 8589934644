import React from 'react'
import { Link } from 'react-router-dom'
import { Box, Divider } from '@mui/material'
import { withTranslation } from 'react-i18next'
import useStyles from './styles'

const Copyright: React.FC = (props: any) => {
  const { t } = props
  const styleClasses = useStyles()

  return (
    <Box className={styleClasses.root}>
      <Link className={styleClasses.link} to='/'>
        {t('home')}
      </Link>
      <Divider className={styleClasses.divider} orientation='vertical' flexItem />
      <Link className={styleClasses.link} to='/overview'>
        {t('overview')}
      </Link>
      <Divider className={styleClasses.divider} orientation='vertical' flexItem />
      <Link className={styleClasses.link} to='/mission-vision'>
        {t('ourMissionVision')}
      </Link>
      <Divider className={styleClasses.divider} orientation='vertical' flexItem />
      <Link className={styleClasses.link} to='/contact-us'>
        {t('ourContact')}
      </Link>
      <Divider className={styleClasses.divider} orientation='vertical' flexItem />
      <Link className={styleClasses.link} to='/our-services'>
        {t('ourServices')}
      </Link>
      <Divider className={styleClasses.divider} orientation='vertical' flexItem />
      <a className={styleClasses.link} href='https://www.facebook.com/TamanSaujanaIdaman/' target='_blank' rel='noopener noreferrer'>
        {t('facebook')}
      </a>
    </Box>
  )
}

export default React.memo(withTranslation()(Copyright))

