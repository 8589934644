import { Project } from '../../../types'

const futureProjectList: Project[] = [
  {
    key: 'tamanHarmoni',
    title: 'Taman Harmoni',
    description: 'Taman Haroni',
    location: '',
    gdv: '',
    contract: '',
    cover: '/images/projects/futures/taman_harmoni/double_storey_banglow_1.jpg',
    flyers: [],
    images: [
      {
        title: 'Semi Detach',
        description: '',
        tags: ['banglow_semi_detach'],
        imagePath: '/images/projects/futures/taman_harmoni/double_storey_banglow_1.jpg',
      },
      {
        title: 'Semi Detach',
        description: '',
        tags: ['banglow_semi_detach'],
        imagePath: '/images/projects/futures/taman_harmoni/double_storey_banglow_2.jpg',
      },
      {
        title: 'Semi Detach',
        description: '',
        tags: ['banglow_semi_detach'],
        imagePath: '/images/projects/futures/taman_harmoni/double_storey_banglow_3.jpg',
      },
      {
        title: 'Semi Detach',
        description: '',
        tags: ['banglow_semi_detach'],
        imagePath: '/images/projects/futures/taman_harmoni/double_storey_banglow_gfloor.jpg',
      },
      {
        title: 'Semi Detach',
        description: '',
        tags: ['banglow_semi_detach'],
        imagePath: '/images/projects/futures/taman_harmoni/double_storey_banglow_ffloor.jpg',
      },
      {
        title: 'Semi Detach',
        description: '',
        tags: ['double_storey_semi_detach'],
        imagePath: '/images/projects/futures/taman_harmoni/double_storey_semid_1.jpg',
      },
      {
        title: 'Semi Detach',
        description: '',
        tags: ['double_storey_semi_detach'],
        imagePath: '/images/projects/futures/taman_harmoni/double_storey_semid_2.jpg',
      },
      {
        title: 'Semi Detach',
        description: '',
        tags: ['double_storey_semi_detach'],
        imagePath: '/images/projects/futures/taman_harmoni/double_storey_semid_3.jpg',
      },
      {
        title: 'Semi Detach',
        description: '',
        tags: ['double_storey_semi_detach'],
        imagePath: '/images/projects/futures/taman_harmoni/double_storey_semid_gfloor.jpg',
      },
      {
        title: 'Semi Detach',
        description: '',
        tags: ['double_storey_semi_detach'],
        imagePath: '/images/projects/futures/taman_harmoni/double_storey_semid_ffloor.jpg',
      },
    ]
  },
  {
    key: 'impiana52',
    title: 'Impiana 52',
    description: 'Impiana 52',
    location: '',
    gdv: '',
    contract: '',
    cover:'/images/projects/futures/impiana52/perspective_view_1.jpeg',
    flyers: [],
    images: [
      {
        title: 'Ground Floor Floorplan',
        description: '',
        tags: ['floor_plan'],
        imagePath: '/images/projects/futures/impiana52/ground_floor_floorplan.jpg',
      },
      {
        title: 'First Floor Floorplan',
        description: '',
        tags: ['floor_plan'],
        imagePath: '/images/projects/futures/impiana52/first_floor_floorplan.jpg',
      },
      {
        title: 'Perspective View',
        description: '',
        tags: ['perspective_view'],
        imagePath: '/images/projects/futures/impiana52/perspective_view_1.jpeg',
      },
      {
        title: 'Perspective View',
        description: '',
        tags: ['perspective_view'],
        imagePath: '/images/projects/futures/impiana52/perspective_view_2.jpeg',
      },
      {
        title: 'Perspective View',
        description: '',
        tags: ['perspective_view'],
        imagePath: '/images/projects/futures/impiana52/perspective_view_3.jpeg',
      },
      {
        title: 'Perspective View',
        description: '',
        tags: ['perspective_view'],
        imagePath: '/images/projects/futures/impiana52/perspective_view_4.jpg',
      },
    ]
  }
]

export default futureProjectList
