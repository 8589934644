import { Project } from '../../../types'

const completedProjectList: Project[] = [
  {
    key: 'tamanSaujanaIdamanPhaseI',
    title: 'Taman Saujana Idaman - Phase I',
    description: 'Taman Saujana Idaman - Phase I',
    location: '',
    gdv: '',
    contract: '',
    cover: '/images/projects/completes/taman_saujana_idaman/phase_II/single_storey/final_1.jpg',
    flyers: [],
    images: [
      {
        title: 'Semi Detach',
        description: '',
        tags: ['semi_detach'],
        imagePath: '/images/projects/completes/taman_saujana_idaman/phase_I/SemiD_1.jpg',
      },
      {
        title: 'Semi Detach',
        description: '',
        tags: ['semi_detach'],
        imagePath: '/images/projects/completes/taman_saujana_idaman/phase_I/SemiD_2.jpg',
      },
      {
        title: 'Semi Detach',
        description: '',
        tags: ['semi_detach'],
        imagePath: '/images/projects/completes/taman_saujana_idaman/phase_I/SemiD_3.jpg',
      },
      {
        title: 'Semi Detach',
        description: '',
        tags: ['semi_detach'],
        imagePath: '/images/projects/completes/taman_saujana_idaman/phase_I/SemiD_4.jpg',
      },
      {
        title: 'Semi Detach',
        description: '',
        tags: ['semi_detach'],
        imagePath: '/images/projects/completes/taman_saujana_idaman/phase_I/SemiD_5.jpg',
      },
      {
        title: 'Semi Detach',
        description: '',
        tags: ['semi_detach'],
        imagePath: '/images/projects/completes/taman_saujana_idaman/phase_I/SemiD_6.jpg',
      },
      {
        title: 'Semi Detach',
        description: '',
        tags: ['semi_detach'],
        imagePath: '/images/projects/completes/taman_saujana_idaman/phase_I/SemiD_7.jpg',
      },
      {
        title: 'Semi Detach',
        description: '',
        tags: ['semi_detach'],
        imagePath: '/images/projects/completes/taman_saujana_idaman/phase_I/SemiD_8.jpg',
      },
      {
        title: 'Semi Detach',
        description: '',
        tags: ['semi_detach'],
        imagePath: '/images/projects/completes/taman_saujana_idaman/phase_I/SemiD_9.jpg',
      },
      {
        title: 'Semi Detach',
        description: '',
        tags: ['semi_detach'],
        imagePath: '/images/projects/completes/taman_saujana_idaman/phase_I/SemiD_10.jpg',
      },
      {
        title: 'Single Storey Terrance',
        description: '',
        tags: ['single_storey'],
        imagePath: '/images/projects/completes/taman_saujana_idaman/phase_I/SS_Terrance_1.jpg',
      },
      {
        title: 'Single Storey Terrance',
        description: '',
        tags: ['single_storey'],
        imagePath: '/images/projects/completes/taman_saujana_idaman/phase_I/SS_Terrance_2.jpg',
      },
      {
        title: 'Double Storey Terrance',
        description: '',
        tags: ['double_storey'],
        imagePath: '/images/projects/completes/taman_saujana_idaman/phase_I/DS_Terrance_1.jpg',
      },
      {
        title: 'Double Storey Terrance',
        description: '',
        tags: ['double_storey'],
        imagePath: '/images/projects/completes/taman_saujana_idaman/phase_I/DS_Terrance_2.jpg',
      },
      {
        title: 'Double Storey Terrance',
        description: '',
        tags: ['double_storey'],
        imagePath: '/images/projects/completes/taman_saujana_idaman/phase_I/DS_Terrance_3.jpg',
      },
      {
        title: 'Double Storey Terrance',
        description: '',
        tags: ['double_storey'],
        imagePath: '/images/projects/completes/taman_saujana_idaman/phase_I/DS_Terrance_4.jpg',
      },
      {
        title: 'Double Storey Terrance',
        description: '',
        tags: ['double_storey'],
        imagePath: '/images/projects/completes/taman_saujana_idaman/phase_I/DS_Terrance_5.jpg',
      },
      {
        title: 'Double Storey Terrance',
        description: '',
        tags: ['double_storey'],
        imagePath: '/images/projects/completes/taman_saujana_idaman/phase_I/DS_Terrance_6.jpg',
      },
      {
        title: 'Double Storey Terrance',
        description: '',
        tags: ['double_storey'],
        imagePath: '/images/projects/completes/taman_saujana_idaman/phase_I/DS_Terrance_7.jpg',
      },
    ],
  },
  {
    key: 'tamanSaujanaIdamanPhaseII',
    title: 'Taman Saujana Idaman - Phase II',
    description: 'Taman Saujana Idaman - Phase II',
    location: '',
    gdv: '',
    contract: '',
    cover:'/images/projects/completes/taman_saujana_idaman/phase_II/single_storey/final_1.jpg',
    flyers: [],
    images: [
      {
        title: 'Single Storey',
        description: '',
        tags: ['single_storey'],
        imagePath: '/images/projects/completes/taman_saujana_idaman/phase_II/single_storey/final_1.jpg',
      },
      {
        title: 'Single Storey',
        description: '',
        tags: ['single_storey'],
        imagePath: '/images/projects/completes/taman_saujana_idaman/phase_II/single_storey/final_2.jpg',
      },
      {
        title: 'Single Storey',
        description: '',
        tags: ['single_storey'],
        imagePath: '/images/projects/completes/taman_saujana_idaman/phase_II/single_storey/final_3.jpg',
      },
      {
        title: 'Single Storey',
        description: '',
        tags: ['single_storey'],
        imagePath: '/images/projects/completes/taman_saujana_idaman/phase_II/single_storey/living_1.jpg',
      },
      {
        title: 'Single Storey',
        description: '',
        tags: ['single_storey'],
        imagePath: '/images/projects/completes/taman_saujana_idaman/phase_II/single_storey/living_2.jpg',
      },
      {
        title: 'Single Storey',
        description: '',
        tags: ['single_storey'],
        imagePath: '/images/projects/completes/taman_saujana_idaman/phase_II/single_storey/kitchen_1.jpg',
      },
      {
        title: 'Single Storey',
        description: '',
        tags: ['single_storey'],
        imagePath: '/images/projects/completes/taman_saujana_idaman/phase_II/single_storey/dining_1.jpg',
      },
      {
        title: 'Single Storey',
        description: '',
        tags: ['single_storey'],
        imagePath: '/images/projects/completes/taman_saujana_idaman/phase_II/single_storey/family_1.jpg',
      },
      {
        title: 'Single Storey',
        description: '',
        tags: ['single_storey'],
        imagePath: '/images/projects/completes/taman_saujana_idaman/phase_II/single_storey/master_room_1.jpg',
      },
      {
        title: 'Single Storey',
        description: '',
        tags: ['single_storey'],
        imagePath: '/images/projects/completes/taman_saujana_idaman/phase_II/single_storey/floorplan_1.jpg',
      },
    ],
  },
  {
    key: 'tamanSaujanaIdamanLowCost',
    title: 'Taman Saujana Idaman - 68K Terrance House',
    description: 'Taman Saujana Idaman - 68K Terrance House',
    location: '',
    gdv: '',
    contract: '',
    cover: '/images/projects/completes/taman_saujana_idaman/low_cost/lowcost_1.jpg',
    flyers: [],
    images: [
      {
        title: '68K Terrance House',
        description: '',
        tags: [],
        imagePath: '/images/projects/completes/taman_saujana_idaman/low_cost/lowcost_1.jpg',
      },
      {
        title: '68K Terrance House',
        description: '',
        tags: [],
        imagePath: '/images/projects/completes/taman_saujana_idaman/low_cost/lowcost_2.jpg',
      },
      {
        title: '68K Terrance House',
        description: '',
        tags: [],
        imagePath: '/images/projects/completes/taman_saujana_idaman/low_cost/lowcost_3.jpg',
      },
      {
        title: '68K Terrance House',
        description: '',
        tags: [],
        imagePath: '/images/projects/completes/taman_saujana_idaman/low_cost/lowcost_4.jpg',
      },
      {
        title: '68K Terrance House',
        description: '',
        tags: [],
        imagePath: '/images/projects/completes/taman_saujana_idaman/low_cost/lowcost_5.jpg',
      },
      {
        title: '68K Terrance House',
        description: '',
        tags: [],
        imagePath: '/images/projects/completes/taman_saujana_idaman/low_cost/lowcost_6.jpg',
      },
      {
        title: '68K Terrance House',
        description: '',
        tags: [],
        imagePath: '/images/projects/completes/taman_saujana_idaman/low_cost/lowcost_7.jpg',
      },
    ],
  },
]

export default completedProjectList