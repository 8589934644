import React from 'react'
import { isMobile as defaultIsMobile, useDeviceSelectors } from 'react-device-detect'
import { withTranslation } from 'react-i18next'
import { Box, Grid } from '@mui/material'
import Header from '../Header'
import Footer from '../Footer'
import AppDrawer from '../Header/components/AppDrawer'
import colors from '../../../theme/colors'

interface IBaseLayout {
  children: React.ReactNode
}

const BaseLayout:React.FC<IBaseLayout> = ({ children }: IBaseLayout) => {
  const [mobileView, setMobileView] = React.useState(defaultIsMobile)
  const [drawerState, setDrawerState] = React.useState(false)
  const [contentHeight, setContentHeight] = React.useState(0)

  React.useEffect(() => {
    setContentHeight(window.innerHeight - 170)

    window.addEventListener('resize', () => {
      const [selector] = useDeviceSelectors(window.navigator.userAgent)
      const { isMobile } = selector

      setMobileView(isMobile)
      setContentHeight(window.innerHeight - 170)
    })
  })

  React.useEffect(() => {

  }, [mobileView, contentHeight])

  const handleDrawerState = () => {
    setDrawerState(!drawerState)
  }


  return (
    <div>
      <Header
        isMobile={mobileView}
        handleDraweState={() => {
          handleDrawerState()
        }} />
      <AppDrawer drawerState={drawerState} handleDraweState={()=>{ handleDrawerState ()}} />
      <Box sx={{ backgroundColor: colors.white, minHeight: contentHeight }}>
        <Grid container>
          {
            React.cloneElement(children, {
              isMobile: mobileView,
              contentHeight: contentHeight
            })
          }
        </Grid>
      </Box>
      <Footer isMobile={mobileView} />
    </div>
  )
}

export default React.memo(withTranslation()(BaseLayout))