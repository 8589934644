import React from 'react'
import { AppBar, Container, Toolbar, Box, IconButton } from '@mui/material'
import useStyles from './styles'
import Menus from './components/Menus'

interface IHeader {
  isMobile: boolean,
  handleDraweState: () => void
}

const Header: React.FC<IHeader> = ({ isMobile, handleDraweState }: IHeader) => {
  const styleClasses = useStyles()

  return (
    <AppBar position="static">
      <Container maxWidth={'xl'}>
        <Toolbar disableGutters>
          <Box
            sx={{
              width: isMobile ? '100%' : 'auto',
              textAlign: isMobile ? 'center' : 'left',
            }}
          >
            {!isMobile ? null : (
              <Box className={styleClasses.menuBox}>
                {/* drawer toggle button  */}
                <IconButton
                  edge="start"
                  color="inherit"
                  aria-label="menu"
                  className={styleClasses.menuIcon}
                  onClick={handleDraweState}
                  size="large"
                >
                  <span className="mdi mdi-menu" />
                </IconButton>
              </Box>
            )}

            {/* logo  */}
            <a href="/">
              <img
                className={styleClasses.logo}
                src="/images/logos/dv-logo-word-horizontal.png"
              />
            </a>
          </Box>
          {/* menu bar */}
          {!isMobile ? (
            <Box sx={{ flexGrow: 1 }}>
              <Menus />
            </Box>
          ) : null}
        </Toolbar>
      </Container>
    </AppBar>
  )
}

export default React.memo(Header)
