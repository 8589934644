import Home from './Home'
import Overview from './AboutUs/Overview'
import OurMissionVision from './AboutUs/OurMissionVision'
import OurServices from './AboutUs/OurServices'
import ContactUs from './ContactUs'
import CompletedProject from './Projects/Completed'
import CurrentProject from './Projects/Current'
import FutureProject from './Projects/Future'
import ConstructionProject from './Projects/Construction'
import Error404 from './Error404'

export default {
  Home,
  Overview,
  OurMissionVision,
  OurServices,
  CompletedProject,
  CurrentProject,
  FutureProject,
  ConstructionProject,
  ContactUs,
  Error404
} as any
