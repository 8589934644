import React from 'react'
import { I18nextProvider } from 'react-i18next'
import { CssBaseline, ThemeProvider } from '@mui/material'
import theme from './theme'
import i18n from './i18n'
import AllRoutes from './routes'

function App() {
  return (
    <I18nextProvider i18n={i18n}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <AllRoutes />
      </ThemeProvider>
    </I18nextProvider>
  )
}

export default App
