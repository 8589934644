import React from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import AllPages from '../pages'
import { Route as vRoute } from '../types'
import BaseLayout from '../components/Layouts/BaseLayout'
import routesConfig from './config'

const AllRoutes = () => {
  return (
    <BrowserRouter>
      <Routes>
        {
          routesConfig.map((r: vRoute) => {
            const Component = r.component && AllPages[r.component]

            if (!r.expand) {
              return (
                <Route
                  key={r.key}
                  index={r.index}
                  path={r.path || ''}
                  element={(
                    <BaseLayout>
                      <Component />
                    </BaseLayout>
                  )}/>
              )
            } else {
              return r.subRoutes && r.subRoutes.map((sr: vRoute) => {
                const Component = sr.component && AllPages[sr.component]

                return (
                  <Route
                    key={sr.key}
                    index={sr.index}
                    path={sr.path || ''}
                    element={(
                      <BaseLayout>
                        <Component />
                      </BaseLayout>
                    )}/>
                )
              })
            }
          })
        }
      </Routes>
    </BrowserRouter>
  )
}

export default AllRoutes