import { Route } from '../types'
import i18n from '../i18n'

const routesConfig: Route[] = [
  {
    index: true,
    key: 'route-home',
    title: i18n.t('home'),
    description: i18n.t('homeDescription'),
    path: '/',
    component: 'Home',
    isEnable: true,
    isHide: false,
  },
  {
    index: false,
    key: 'route-about-us',
    title: i18n.t('aboutUs'),
    description: i18n.t('aboutUsDescription'),
    path: '/about-us',
    isEnable: true,
    component: '',
    subRoutes: [
      {
        index: true,
        key: 'route-overview',
        title: i18n.t('overview'),
        description: 'Overview',
        path: '/overview',
        component: 'Overview',
        isEnable: true,
        isHide: false,
      },
      {
        index: false,
        key: 'route-mission-vision',
        title: i18n.t('ourMissionVision'),
        description: i18n.t('ourMissionVisionDescription'),
        path: '/mission-vision',
        component: 'OurMissionVision',
        isEnable: true,
        isHide: false,
      },
      {
        index: false,
        key: 'route-our-services',
        title: i18n.t('ourServices'),
        description: i18n.t('ourServicesDescription'),
        path: '/our-services',
        component: 'OurServices',
        isEnable: true,
        isHide: false,
      },
    ],
    expand: true,
    isHide: false,
  },
  {
    index: false,
    key: 'route-projects',
    title: i18n.t('project'),
    description: i18n.t('projectDescription'),
    path: '/projects',
    component: '',
    isEnable: true,
    subRoutes: [
      {
        index: true,
        key: 'route-current-project',
        title: i18n.t('currentProject'),
        description: i18n.t('currentProjectDescription'),
        path: '/current',
        component: 'CurrentProject',
        isEnable: true,
        isHide: false,
      },
      {
        index: true,
        key: 'route-current-project',
        title: i18n.t('currentProject'),
        description: i18n.t('currentProjectDescription'),
        path: '/current/:projectName',
        component: 'CurrentProject',
        isEnable: true,
        isHide: true,
      },
      {
        index: false,
        key: 'route-future-project',
        title: i18n.t('futureProject'),
        description: i18n.t('futureProjectDescription'),
        path: '/future',
        component: 'FutureProject',
        isEnable: true,
        isHide: false,
      },
      {
        index: false,
        key: 'route-future-project',
        title: i18n.t('futureProject'),
        description: i18n.t('futureProjectDescription'),
        path: '/future/:projectName',
        component: 'FutureProject',
        isEnable: true,
        isHide: true,
      },
      {
        index: false,
        key: 'route-construction-project',
        title: i18n.t('constructionProject'),
        description: i18n.t('constructionProjectDescription'),
        path: '/construction',
        component: 'ConstructionProject',
        isEnable: true,
        isHide: false,
      },
      {
        index: false,
        key: 'route-construction-project-selected',
        title: i18n.t('constructionProject'),
        description: i18n.t('constructionProjectDescription'),
        path: '/construction/:projectName',
        component: 'ConstructionProject',
        isEnable: true,
        isHide: true,
      },
      {
        index: false,
        key: 'route-completed-project',
        title: i18n.t('completedProject'),
        description: i18n.t('completedProjectDescription'),
        path: '/completed',
        component: 'CompletedProject',
        isEnable: true,
        isHide: false,
      },
      {
        index: false,
        key: 'route-completed-project',
        title: i18n.t('completedProject'),
        description: i18n.t('completedProjectDescription'),
        path: '/completed/:projectName',
        component: 'CompletedProject',
        isEnable: true,
        isHide: true,
      },
    ],
    expand: true,
    isHide: false,
  },
  {
    index: false,
    key: 'route-contact-us',
    title: i18n.t('getInTouch'),
    description: i18n.t('getInTouchDescription'),
    path: '/contact-us',
    component: 'ContactUs',
    isEnable: true,
    isHide: false,
  },
  {
    index: false,
    key: 'route-error-404',
    title: i18n.t('error404'),
    description: i18n.t('error404Description'),
    path: '*',
    component: 'Error404',
    isEnable: true,
    isHide: true,
  },
]

export default routesConfig
