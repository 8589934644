import React from 'react'
import { Container, Box } from '@mui/material'
import { withTranslation } from 'react-i18next'
import useStyles from './styles'
import MenuLink from './components/MenuLink'
import Copyright from './components/Copyright'

interface IFooter {
  isMobile: boolean,
}

const Footer:React.FC<IFooter> = ({ isMobile }: IFooter) => {
  const styleClasses = useStyles()

  return (
    <Box className={styleClasses.footer}>
      <Container maxWidth={'xl'} className={styleClasses.footerContent}>
        { (isMobile) ? null : <MenuLink /> }
        <Copyright />
      </Container>
    </Box>
  )
}

export default React.memo(withTranslation()(Footer))
