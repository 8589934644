import React from 'react'
import ProjectPanel from '../../../components/ProjectPanel'
import { withTranslation } from 'react-i18next'
import currentProjectList from './data'

const CurrentProject:React.FC = (props:any) => {
  const { t, contentHeight } = props

  return (
    <ProjectPanel
      title={t('currentProjects')}
      projectData={currentProjectList}
      contentHeight={contentHeight} />
  )
}

export default React.memo(withTranslation()(CurrentProject))