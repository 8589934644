import { Project } from '../../../types'

const currentProjectList: Project[] = [
  {
    key: 'tamanSaujanaIdamanPhaseTerraceSingleHalfStorey',
    title: 'Taman Saujana Idaman - Phase II',
    subTitle: '(Rumah Teres 1.5 Tingkat)',
    description: 'Taman Saujana Idaman - Phase II 1.5 Storey Terrace',
    location: '',
    gdv: '',
    contract: '',
    cover:'/images/projects/currents/taman_saujana_idaman/1_5_storey/1_5_storey_5.jpg',
    flyers: [],
    images: [
      {
        title: 'Single Half Storey',
        description: '',
        tags: ['single_half_storey'],
        imagePath: '/images/projects/currents/taman_saujana_idaman/1_5_storey/1_5_storey_1.jpg',
      },
      {
        title: 'Single Half Storey',
        description: '',
        tags: ['single_half_storey'],
        imagePath: '/images/projects/currents/taman_saujana_idaman/1_5_storey/1_5_storey_2.jpg',
      },
      {
        title: 'Single Half Storey',
        description: '',
        tags: ['single_half_storey'],
        imagePath: '/images/projects/currents/taman_saujana_idaman/1_5_storey/1_5_storey_3.jpg',
      },
      {
        title: 'Single Half Storey',
        description: '',
        tags: ['single_half_storey'],
        imagePath: '/images/projects/currents/taman_saujana_idaman/1_5_storey/1_5_storey_4.jpg',
      },
      {
        title: 'Single Half Storey',
        description: '',
        tags: ['single_half_storey'],
        imagePath: '/images/projects/currents/taman_saujana_idaman/1_5_storey/1_5_storey_5.jpg',
      },
      {
        title: 'Single Half Storey',
        description: '',
        tags: ['single_half_storey'],
        imagePath: '/images/projects/currents/taman_saujana_idaman/1_5_storey/1_5_storey_6.jpg',
      },
    ],
  },
  {
    key: 'tamanSaujanaIdamanPhaseTerraceDoubleStorey',
    title: 'Taman Saujana Idaman - Phase II',
    subTitle: '(Rumah Teres 2 Tingkat)',
    description: 'Taman Saujana Idaman - Phase II Double Storey Terrace',
    location: '',
    gdv: '',
    contract: '',
    cover:'/images/projects/currents/taman_saujana_idaman/2_storey/2_storey_1.jpg',
    flyers: [],
    images: [
      {
        title: 'Double Storey',
        description: '',
        tags: ['double_storey'],
        imagePath: '/images/projects/currents/taman_saujana_idaman/2_storey/2_storey_1.jpg',
      },
      {
        title: 'Double Storey',
        description: '',
        tags: ['double_storey'],
        imagePath: '/images/projects/currents/taman_saujana_idaman/2_storey/2_storey_2.jpg',
      },
      {
        title: 'Double Storey',
        description: '',
        tags: ['double_storey'],
        imagePath: '/images/projects/currents/taman_saujana_idaman/2_storey/2_storey_3.jpg',
      },
      {
        title: 'Double Storey',
        description: '',
        tags: ['double_storey'],
        imagePath: '/images/projects/currents/taman_saujana_idaman/2_storey/2_storey_4.jpg',
      },
      {
        title: 'Double Storey',
        description: '',
        tags: ['double_storey'],
        imagePath: '/images/projects/currents/taman_saujana_idaman/2_storey/2_storey_first_floor.jpg',
      },
      {
        title: 'Single Half Storey',
        description: '',
        tags: ['double_storey'],
        imagePath: '/images/projects/currents/taman_saujana_idaman/2_storey/2_storey_ground_floor.jpg',
      },
    ],
  },
  {
    key: 'tamanSriGunung',
    title: 'Taman Sri Gunung',
    description: 'Taman Sri Gunung',
    location: '',
    gdv: '',
    contract: '',
    cover:'/images/projects/currents/taman_sri_gunung/taman_sri_gunung_bungalow_perspective_view_1.jpg',
    flyers: [],
    images: [
      {
        title: 'Bungalow',
        description: '',
        tags: ['bungalow'],
        imagePath: '/images/projects/currents/taman_sri_gunung/taman_sri_gunung_bungalow_perspective_view_1.jpg',
      },
      {
        title: 'Bungalow',
        description: '',
        tags: ['bungalow'],
        imagePath: '/images/projects/currents/taman_sri_gunung/taman_sri_gunung_bungalow_perspective_view_2.jpg',
      },
      {
        title: 'Bungalow',
        description: '',
        tags: ['bungalow'],
        imagePath: '/images/projects/currents/taman_sri_gunung/taman_sri_gunung_bungalow_perspective_view_3.jpg',
      },
      {
        title: 'Bungalow',
        description: '',
        tags: ['bungalow'],
        imagePath: '/images/projects/currents/taman_sri_gunung/taman_sri_gunung_bungalow_floor_plan.jpg',
      },
      {
        title: 'Semi D',
        description: '',
        tags: ['semiD'],
        imagePath: '/images/projects/currents/taman_sri_gunung/taman_sri_gunung_semi_d_perspective_view_1.jpg',
      },
      {
        title: 'Semi D',
        description: '',
        tags: ['semiD'],
        imagePath: '/images/projects/currents/taman_sri_gunung/taman_sri_gunung_semi_d_perspective_view_2.jpg',
      },
      {
        title: 'Semi D',
        description: '',
        tags: ['semiD'],
        imagePath: '/images/projects/currents/taman_sri_gunung/taman_sri_gunung_semi_d_perspective_view_3.jpg',
      },
      {
        title: 'Semi D',
        description: '',
        tags: ['semiD'],
        imagePath: '/images/projects/currents/taman_sri_gunung/taman_sri_gunung_semi_d_floor_plan_1.jpg',
      },
      {
        title: 'Semi D',
        description: '',
        tags: ['semiD'],
        imagePath: '/images/projects/currents/taman_sri_gunung/taman_sri_gunung_semi_d_floor_plan_2.jpg',
      },
    ],
  },
  {
    key: 'tamanSaujanaBintongPPAM',
    title: 'Taman Saujana Bintong PPAM',
    subTitle: '',
    description: 'Taman Saujana Bintong PPAM',
    location: '',
    gdv: '',
    contract: '',
    cover:'/images/projects/currents/taman_saujana_bintong_ppam/ppam_1.jpg',
    flyers: [],
    images: [
      {
        title: 'PPAM',
        description: '',
        tags: ['ppam'],
        imagePath: '/images/projects/currents/taman_saujana_bintong_ppam/ppam_1.jpg',
      },
      {
        title: 'PPAM',
        description: '',
        tags: ['ppam'],
        imagePath: '/images/projects/currents/taman_saujana_bintong_ppam/ppam_2.jpg',
      },
      {
        title: 'PPAM',
        description: '',
        tags: ['ppam'],
        imagePath: '/images/projects/currents/taman_saujana_bintong_ppam/ppam_3.jpg',
      },
      {
        title: 'PPAM',
        description: '',
        tags: ['ppam'],
        imagePath: '/images/projects/currents/taman_saujana_bintong_ppam/ppam_4.jpg',
      },
      {
        title: 'PPAM',
        description: '',
        tags: ['ppam'],
        imagePath: '/images/projects/currents/taman_saujana_bintong_ppam/ppam_5.jpg',
      },
      {
        title: 'PPAM',
        description: '',
        tags: ['ppam'],
        imagePath: '/images/projects/currents/taman_saujana_bintong_ppam/ppam_6.jpg',
      }
    ],
  },
]

export default currentProjectList