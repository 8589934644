import { makeStyles } from '@mui/styles'
import colors from '../../../../theme/colors'

const useStyles = makeStyles(() => ({
  root: {
    paddingTop: 30,
    textAlign: 'justify'
  },
  link: {
    textDecoration: 'none !important'
  },
  cardTitle: {
    color: colors.theme.green,
    fontWeight: 'bolder',
    textTransform: 'uppercase',
    textAlign: 'center',
    height: '80px'
  },
  cardSubTitle: {
    color: colors.theme.green,
    fontWeight: 'bolder',
    textTransform: 'uppercase',
    textAlign: 'center',
  },
  cardMedia: {
    height: '220px',
    borderTop: `1px solid ${colors.grey_light}`,
    borderBottom: `1px solid ${colors.grey_light}`
  },
  cardContent: {
    textAlign: 'center',
  },
  cardActions: {
    display: 'block !important',
    textAlign: 'center',
  }
}))

export default useStyles