import { makeStyles } from '@mui/styles'
import colors from '../../../../../theme/colors'

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
    color: colors.white,
  },
  link: {
    textDecoration: 'none',
  },
  title: {

    '& span': {
      fontSize: '12pt important',
      fontWeight: 'bolder !important',
      color: `${colors.theme.green} !important`
    }
  }
}))

export default useStyles
