import { makeStyles } from '@mui/styles'
import colors from '../../../theme/colors'

const useStyles = makeStyles(() => ({
  header: {
    width: '100%',
    color: colors.white,
  },
  logo: {
    height: '48px',
  },
  menuBox: {
    float: 'left',
    position: 'absolute',
  },
  menuIcon: {
    marginRight: '15px',
  },
}))

export default useStyles
