import React from 'react'
import { Box } from '@mui/material'
import { withTranslation } from 'react-i18next'
import moment from 'moment-timezone'
import useStyles from './styles'

const Copyright: React.FC = (props: any) => {
  const { t } = props
  const styleClasses = useStyles()

  return (
    <Box className={styleClasses.root}>
      {t('footerCopyright', {
        startYear: 2018,
        currentYear: moment().year(),
      })}
    </Box>
  )
}

export default React.memo(withTranslation()(Copyright))

