import { makeStyles } from '@mui/styles'
import colors from '../../theme/colors'

const useStyles = makeStyles(() => ({
  caption: {
    backgroundColor: colors.theme.green,
    opacity: 0.7,
    position: 'absolute',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
    padding: '15px',
  },
  captionText: {
    opacity: 1,
    fontSize: '18pt',
    color: colors.white,
  },
}))

export default useStyles
