import { SwiperSlide } from '../../types'
import i18n from '../../i18n'

const slideList: SwiperSlide[] = [
  {
    imagePath: '/images/carousels/main_page_cover_1.jpg',
    caption: i18n.t('everyoneDeservesTheOpportunityOfHome')
  },
  {
    imagePath: '/images/carousels/main_page_cover_2.jpg',
    caption: i18n.t('homeTamanSaujanaIdaman')
  },
  {
    imagePath: '/images/carousels/main_page_cover_3.jpg',
    caption: i18n.t('comingSoonToAClosingNearYou')
  },
  {
    imagePath: '/images/carousels/main_page_cover_4.jpg',
    caption: i18n.t('theCompanyYouCanTrust')
  },
]

export default slideList
