import { makeStyles } from '@mui/styles'
import colors from '../../../../theme/colors'

const useStyles = makeStyles(() => ({
  root: {
    paddingTop: 30,
    textAlign: 'justify'
  },
  noDataIcon: {
    fontSize: '80pt',
    color: colors.theme.green,
  },
  noDataWord: {
    fontWeight: ' !important',
    color: colors.theme.green,
  }
}))

export default useStyles
