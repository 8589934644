import colors from './colors'

export default {
  white: colors.white,
  black: colors.black,
  primary: {
    contrastText: colors.white,
    main: colors.theme.green,
  },
  secondary: {
    contrastText: colors.white,
    main: colors.theme.red,
  },
  background: {
    default: colors.grey,
    paper: colors.white,
  },
}
