/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react'
import clsx from 'clsx'
import { Link } from 'react-router-dom'

import { Box, Button, Menu, MenuItem } from '@mui/material'
import useStyles from './styles'

import { Route } from '../../../../../types'
import routesConfig from '../../../../../routes/config'

const Menus: React.FC = () => {
  const styleClasses = useStyles()
  const [menuEl, setMenuEl] = React.useState<null | HTMLElement>(null)
  const [childMenu, setChildMenu] = React.useState('')

  const handleMenuEl = (event: any, childName: string) => {
    setMenuEl(event.currentTarget)
    setChildMenu(childName)
  }

  const handleMenuClose = () => {
    setMenuEl(null)
    setChildMenu('')
  }

  return (
    <Box className={styleClasses.menu}>
      {routesConfig.map((route: Route, index: number) => {
        let buttonProps
        if (route.isEnable && !route.isHide) {
          buttonProps = {
            className: clsx(
              styleClasses.menuNavItem,
              route.key === 'route-contact-us' ? styleClasses.getInTouch : null
            ),
          }

          if (route.expand) {
            buttonProps = {
              ...buttonProps,
              'aria-controls': `menu-${route.key}`,
              'aria-haspopup': true,
              onClick: (event: object) => {
                if (childMenu === '') {
                  handleMenuEl(event, `menu-${route.key}`)
                } else {
                  handleMenuClose()
                }
              },
            }
          }

          return (
            <Button key={`btn-nav-${index}`} {...buttonProps}>
              {(route.expand) ? (
                <span className={styleClasses.menuNavItemLink}>
                  {route.title}
                  <span
                    className={clsx(
                      'mdi',
                      'mdi-chevron-down',
                      styleClasses.menuDropDown
                    )}
                  />
                  <Menu
                    id={`menu-${route.key}`}
                    anchorEl={menuEl}
                    open={Boolean(childMenu === `menu-${route.key}`)}
                    onClose={handleMenuClose}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                    transformOrigin={{ vertical: 'top', horizontal: 'left' }}
                    PaperProps={{ style: { minWidth: 200, maxWidth: 250 } }}
                  >
                    {route.subRoutes && route.subRoutes.length > 0
                      ? route.subRoutes.map((sRoute: Route, sIndex: number) => {
                        if (sRoute.isEnable && !sRoute.isHide) {
                          return (
                            <Link
                              key={`link-nav-${index}-${sIndex}`}
                              to={sRoute.path ?? ''}
                              className={styleClasses.menuNavItemLink}
                            >
                              <MenuItem
                                key={`menu-item-${index}-${sIndex}`}
                                onClick={handleMenuClose}
                              >
                                {sRoute.title}
                              </MenuItem>
                            </Link>
                          )
                        }
                        return null
                      })
                      : null}
                  </Menu>
                </span>
              ) : (
                <Link
                  key={`link-nav-${index}`}
                  to={route.path ?? ''}
                  className={styleClasses.menuNavItemLink}
                >
                  {route.title}
                </Link>
              )}
            </Button>
          )
        }

        return null
      })}
    </Box>
  )
}

export default Menus
