
import React from 'react'
import _ from 'lodash'
import { useParams } from 'react-router-dom';
import { Project, ProjectImage, Flyer } from '../../types'
import { Grid, Container, IconButton, Typography, Divider, ImageList, ImageListItem, ImageListItemBar, useMediaQuery } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import { useTranslation } from 'react-i18next'
import useStyles from './styles'
import ProjectCard from './components/ProjectCard'
import EmptyData from './components/EmptyData';
import ImageViewer from './components/ImageViewer';
import ImageSearchIcon from '@mui/icons-material/ImageSearch'

interface IProjectPanel {
  title: string
  projectData: Project[]
  contentHeight: number
}

const ProjectPanel:React.FC<IProjectPanel> = ({ title, projectData, contentHeight}: IProjectPanel) => {
  const [projectTitle, setProjectTitle] = React.useState(title)
  const [projectSelected, setProjectSelected] = React.useState({})
  const [imageTitle, setImageTitle] = React.useState('')
  const [imagePath, setImagePath] = React.useState('')
  const [isEnlarge, setIsEnlarge] = React.useState(false)
  const { projectName } = useParams();
  const { t } = useTranslation()
  const styleClasses = useStyles()
  const theme = useTheme();
  const greaterThanMid = useMediaQuery(theme.breakpoints.up('md'));
  const smallToMid = useMediaQuery(theme.breakpoints.between('sm', 'md'));

  React.useEffect(() => {
    if (projectName !== undefined) {
      const projectFiltered = _.filter(projectData, (p) => p.key === projectName)

      if (!_.isEmpty(projectFiltered)) {
        setProjectTitle(projectFiltered[0]['title'])
        setProjectSelected(projectFiltered[0])
      }
    }
  }, [projectName])

  React.useEffect(() => {

  }, [projectSelected]);

  const getColumnValues = () => {
    if (greaterThanMid) {
      return 3
    }
    else if (smallToMid) {
      return 2
    }
    else {
      return 1
    }
  }

  const enlargeImage = (title: string, imagePath: string) => {
    setImageTitle(title)
    setImagePath(imagePath)
    setIsEnlarge(true)
  }

  const renderBody = () => {
    if (projectName === undefined) {
      return projectData.map((p: Project, index: number) => {
        return (
          <Grid key={`grid-project-${index}`} item xs={12} sm={6} md={4} lg={4}>
            <ProjectCard
              key={`project-card-${index}`}
              projectKey={p.key}
              title={p.title}
              subTitle={p.subTitle}
              coverPath={p.cover} />
          </Grid>
        )
      });
    } else if (projectName !== undefined && !_.isEmpty(projectSelected)) {
      if (projectSelected['images'].length > 0) {
        return (
          <ImageList variant="quilted" cols={getColumnValues()} rowHeight={256} gap={10}>
            {
              projectSelected['images'].map((img:ProjectImage, index:number) => {
                return (
                  <ImageListItem key={`constructor-image-${index}`}>
                    <img
                      className={styleClasses.imageItem}
                      src={`${img.imagePath}?w=auto&fit=crop&auto=format`}
                      srcSet={`${img.imagePath}?w=auto&fit=crop&auto=format&dpr=2 2x`}
                      alt={`${img.tags}`}
                      loading="lazy" />
                    <ImageListItemBar
                      title={img.title}
                      subtitle={img.description}
                      actionIcon={
                        <IconButton
                          sx={{ color: '#ffffff' }}
                          aria-label={`${t('Enlarge')} ${img.title}`}
                          onClick={() => enlargeImage(img.title, img.imagePath)}
                        >
                          <ImageSearchIcon />
                        </IconButton>
                      }
                    />
                  </ImageListItem>
                )

              })
            }
          </ImageList>
        )
        return projectSelected['images'].map((img:ProjectImage, index:number) => {
          return img.title
        })
      }
    }

    return (
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <EmptyData />
      </Grid>
    )
  }

  return (
    <Container className={styleClasses.root}>
      <Grid
        container
        sx={{ minHeight: contentHeight }}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Typography
              variant='h4'
              gutterBottom
              className={styleClasses.title}
              component='div'>
              { projectTitle }
            </Typography>
            <Divider />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            {
              (_.isEmpty(projectData)) ?
                <EmptyData /> :
                <Grid container spacing={2}>
                  { renderBody() }
                </Grid>
            }
          </Grid>
        </Grid>
      </Grid>
      <ImageViewer
        title={imageTitle}
        imagePath={imagePath}
        state={isEnlarge}
        setState={setIsEnlarge}
      />
    </Container>
  )
}

export default React.memo(ProjectPanel)