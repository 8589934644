import { makeStyles } from '@mui/styles'
import colors from '../../theme/colors'

const useStyles = makeStyles(() => ({
  root: {
    padding: 30,
    textAlign: 'center',
  },
  title: {
    color: colors.theme.green,
    fontWeight: 'bolder',
    textTransform: 'uppercase'
  },
  backButton: {
    float: 'left',
    fontSize: '10pt !important',
  },
  imageItem: {
    border: `1px solid ${colors.theme.green}`,
    height: 280,
    width: '100%',
    boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
  }
}))

export default useStyles
