import { createTheme } from '@mui/material/styles'

import palette from './palette'
import typography from './typography'
import MuiAppBar from './components/MuiAppBar'

export default createTheme({
  palette: { ...palette },
  typography: { ...typography },
  components: {
    MuiAppBar,
  },
})
